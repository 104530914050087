import * as React from 'react';
import {useContext} from 'react';
import {Alert, Stack} from "@mui/material";
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import PlatformTextField from "../../components/platform/platformTextField";
import CheckoutStatus from "./checkoutStatus";
import CheckoutPaymentForm from "./checkoutPaymentForm";
import {CheckoutPaymentFormContextProvider} from "./checkoutPaymentFormContext";
import CheckoutAddressForm from "./checkoutAddressForm";
import usePlatformForm from "../../components/hook/usePlatformForm";
import {isDesktop, isMobile} from "react-device-detect";
import {LockOutlined} from "@mui/icons-material";
import CheckoutContext from "./checkoutContext";
import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from "../../components/hook/useRecaptcha";

export default function CheckoutStep3Payment(props) {
    const {refreshCart, onReCAPTCHAChange, showCaptcha, orderErrorMessage} = useContext(CheckoutContext);
    //const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

    if (isDesktop) {
        usePlatformForm();
    }

    const onBillingTypeChange = () => {
        refreshCart();
    }

    const onPaymentChange = () => {
        refreshCart();
    }

    return (
        <React.Fragment>
            {!isMobile &&
                <React.Fragment>
                    <CheckoutStatus/>
                    <PlatformSpace height={40}/>
                </React.Fragment>
            }
            <Typography variant="h8">
                Payment
            </Typography>
            <PlatformSpace height={5}/>
            <Subtitle>
                All transactions are secure and encrypted.
            </Subtitle>
            <PlatformSpace height={15}/>
            <CheckoutPaymentFormContextProvider name="paymentType" defaultOptionType="CREDIT_CARD" onChange={onPaymentChange}>
                <CheckoutPaymentForm
                    name="CREDIT_CARD"
                    topRow
                    header={
                        <Typography variant="body1">
                            Credit card
                        </Typography>
                    }
                    content={
                        <div>
                            <PlatformTextField
                                name="cardNumber"
                                placeholder="Card number"
                                errorText="Card number is required"
                                endAdornment={<LockOutlined/>}
                                creditCard
                                required
                                fullWidth
                            />
                            <PlatformSpace height={15}/>
                            <PlatformTextField
                                name="cardholder"
                                placeholder="Name on card"
                                errorText="Card name is required"
                                required
                                fullWidth
                            />
                            <PlatformSpace height={15}/>
                            <Stack justifyContent="space-between" direction="row" alignItems="flex-start" spacing={2}>
                                <PlatformTextField
                                    name="expirationDate"
                                    placeholder="Exp. date"
                                    errorText="Date is required"
                                    creditCardDate
                                    required
                                    fullWidth
                                />
                                <PlatformTextField
                                    name="cvv"
                                    placeholder="CVV"
                                    errorText="CVV is required"
                                    cvv
                                    required
                                    fullWidth
                                />
                            </Stack>
                            <PlatformSpace height={15}/>
                            {orderErrorMessage && <Alert severity="error">{orderErrorMessage}</Alert>}
                            {showCaptcha &&
                                <React.Fragment>
                                    <PlatformSpace height={15} />
                                    <ReCAPTCHA
                                        sitekey="6LdQuSkqAAAAAB3CqMZFTEcSgthRd3oldlPBV9_m"
                                        onChange={onReCAPTCHAChange}
                                    />
                                </React.Fragment>
                            }
                        </div>
                    }
                />
            </CheckoutPaymentFormContextProvider>
            <PlatformSpace height={40}/>
            <Typography variant="h8">
                Billing address
            </Typography>
            <PlatformSpace height={5}/>
            <Subtitle>
                Select the address that matches your card or payment method.
            </Subtitle>
            <PlatformSpace height={15}/>
            <CheckoutPaymentFormContextProvider name="billingAddressType" onChange={onBillingTypeChange}>
                <CheckoutPaymentForm
                    name="SHIPPING_ADDRESS"
                    scrollBy={-300}
                    topRow
                    header={
                        <Typography variant="body1">
                            Same as shipping address
                        </Typography>
                    }
                    content={null}
                />
                <CheckoutPaymentForm
                    name="BILLING_ADDRESS"
                    scrollBy={300}
                    bottomRow
                    header={
                        <Typography variant="body1">
                            Use a different billing address
                        </Typography>
                    }
                    content={
                        <CheckoutAddressForm nested={true} groupBy="billingAddress" validateOn="BILLING_ADDRESS"/>
                    }
                />
            </CheckoutPaymentFormContextProvider>
        </React.Fragment>
    )
}

const Subtitle = styled("span")(({ theme }) => ({
    fontSize: "0.8rem",
    color: "black",
    opacity: 0.6,
    fontWeight: 300
}));