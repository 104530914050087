import React, {useEffect, useState} from "react";
import PublicApi from "./publicApi";
import {useHistory} from "react-router-dom";
import {fromCategoryHandle} from "../util/stringUtil";

export const PublicContext = React.createContext({});
export default PublicContext;

export const PublicContextProvider = ({children}) => {
    const [blurBackground, setBlurBackground] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [showCart, setShowCart] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [productsByCategory, setProductsByCategory] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
    const [storeWideSale, setStoreWideSale] = useState(null);
    const history = useHistory();
    const [showPromoBanner, setShowPromoBanner] = useState(() => {
        const saved = localStorage.getItem('showPromoBanner');
        return saved === null ? true : JSON.parse(saved);
    });

    useEffect(() => {
        localStorage.setItem('showPromoBanner', JSON.stringify(showPromoBanner));
    }, [showPromoBanner]);

    useEffect(() => {
        PublicApi.getProductsGroupedByCategory((data) => {
            setProductsByCategory(data);
        })
        PublicApi.getSales((data) => {
            if (data?.length > 0) {
                setStoreWideSale(data[0]);
                //localStorage.setItem('sale', JSON.stringify(data[0])); // Update local storage when closing
            }
        })
    }, [])

    const onClosePromoBanner = () => {
        setShowPromoBanner(false);
        localStorage.setItem('showPromoBanner', JSON.stringify(false)); // Update local storage when closing
    };

    const onContact = () => {
        setShowCart(false);
        setShowContact(true);
    }

    const onCategory = (categoryHandle, productName) => {
        if (productName) {
            history.push({
                pathname: `/catalog/${fromCategoryHandle(categoryHandle)}`,
                search: `?Brand=${productName}`
            });
        } else {
            history.push({
                pathname: `/catalog/${fromCategoryHandle(categoryHandle)}`,
            });
        }
    }

    const onSubscribeEmail = (email, callback) => {
        PublicApi.subscribeEmail(email, (data) => {
            callback();
        })
    }

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const fetchProducts = (queryText, callback) => {
        PublicApi.fetchProducts(queryText, (data) => {
            callback(data);
        })
    }

    const onDetail = (product, variant) => {
        history.push({
            pathname: `/catalog/${fromCategoryHandle(product.categoryHandle)}/product/${product.productId}/variant/${variant.variantId}/${variant.slug}`,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <PublicContext.Provider
            value={{
                fetchProducts,
                onDetail,
                onSubscribeEmail,
                onClosePromoBanner,
                onContact,
                onCategory,
                storeWideSale,
                width,
                height,
                showCart,
                setShowCart,
                blurBackground,
                setBlurBackground,
                showPromoBanner,
                productsByCategory,
                showContact,
                setShowContact,
                setMobileMenuOpen,
                mobileMenuOpen,
                mobileSearchOpen,
                setMobileSearchOpen
            }}
        >
            <>{children}</>
        </PublicContext.Provider>
    );
}