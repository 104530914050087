export default function formatToUSD(value) {
    if (isNaN(value)) {
        return '--';
    }

    // Format the number to USD currency
    let formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

    // Check if the formatted value ends with '.00' and adjust if necessary
    if (formatted.endsWith('.00')) {
        return formatted.slice(0, -3);
    }
    return formatted;
}

export const calculatePrices = (variant, quantity) => {
    const price = variant?.price * quantity;
    const salePrice = variant?.salePrice >= 0 ? variant.salePrice * quantity : null;

    const formattedPrice = formatToUSD(price);
    const formattedSalePrice = salePrice ? formatToUSD(salePrice) : null;

    const percentOff = salePrice ? Math.round(((price - salePrice) / price) * 100) : null;

    return { formattedPrice, formattedSalePrice, percentOff };
};