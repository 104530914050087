import React, {useContext, useEffect, useState} from "react";
import PlatformFormContext from "../../../components/platform/platformFormContext";
import CartContext from "../../cart/cartContext";
import CatalogItemApi from "./catalogItemApi";
import {useParams} from "react-router-dom";
import {usePageTitle} from "../../../components/hook/usePageTitle";
import {deriveConstraintsFromVariant,} from "../../../components/util/productUtil";
import downloadPdf from "../../../components/util/downloadPdf";
import generateVariantName from "../../../components/util/variantNameGenerator";

export const CatalogItemContext = React.createContext({});
export default CatalogItemContext;

export const CatalogItemContextProvider = ({mode, p, v, children}) => {
    const {
        setFormValue
    } = useContext(PlatformFormContext);

    const {addToCart, buyItNow} = useContext(CartContext);
    const {getForm} = useContext(PlatformFormContext);
    const [variant, setVariant] = useState({});
    const [flavor, setFlavor] = useState("");
    const [product, setProduct] = useState({name: ""});
    const [buttonPermutations, setButtonPermutations] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [loading, setLoading] = useState(true);
    const [keyOptionName, setKeyOptionName] = useState(true);
    const [keyOptionValue, setKeyOptionValue] = useState(true);
    const [isFixedOption, setFixedOption] = useState(false);
    const [category, setCategory] = useState(false);
    const [coas, setCOAs] = useState([]);
    const {productId, variantId} = useParams();

    usePageTitle(`Greenbelt.co - ${product?.name} - ${variant?.name}`, variant);

    useEffect(() => {
        if (mode === "modal") {
            CatalogItemApi.getProduct(p.productId, (product) => {
                const variant = product.variants.find(variant => variant.variantId === v.variantId);
                processProduct(product, variant);
            })
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [mode]);

    useEffect(() => {
        setFormValue("variantId", variant?.variantId)
        setFormValue("productId", product?.productId)
    }, [variant, product]);

    useEffect(() => {
        CatalogItemApi.getProduct(productId, (product) => {
            const variant = product.variants.find(variant => variant.variantId === variantId);
            processProduct(product, variant);
        })
    }, [productId, variantId])

    useEffect(() => {
        updateProductTitle();
    }, [buttonPermutations]);

    const updateProductTitle = () => {
        const title = generateVariantName(product, variant);
        //setFlavor(title);
        setFlavor(variant.name)
    }

    const onSelectOption = (name, value) => {
        selectedOptions[name] = value;
        CatalogItemApi.getButtonPermutations(product.productId, selectedOptions, name, value,
            isFixedOption ? keyOptionName : null,
            isFixedOption ? keyOptionValue : null, (permutations) => {
            const selectedVariant = findVariantByOptions(product, selectedOptions);
            setVariant(selectedVariant);
            setSelectedOptions(selectedOptions);
            setButtonPermutations(permutations);
        })
    };

    const findOptionWithLowestSortOrder = (product) => {
        let variantWithLowestSortOrder = null;
        let lowestSortOrder = Infinity;

        product.variantOptions.forEach(option => {
            if (option.sortOrder < lowestSortOrder) {
                lowestSortOrder = option.sortOrder;
                variantWithLowestSortOrder = option;
            }
        });

        return variantWithLowestSortOrder;
    }
    const findVariantByOptions = (product, optionValues) => {
        for (let variant of product.variants) {
            // Normalize variant option values for comparison
            let match = variant.variantOptionValues.every(optionValue => {
                // Normalize both keys and values for case and trim comparisons
                const providedValue = optionValues[optionValue.name]?.toLowerCase().trim();
                const variantValue = optionValue.value?.toLowerCase().trim();

                return providedValue === variantValue;
            });

            if (match) {
                return variant;
            }
        }
        return null;
    };

    const onDownloadCOA = (pdf) => {
        downloadPdf(pdf);
    }

    const getCOAs = (variant) => {
        CatalogItemApi.getBatchesByProductVariantId(variant.variantId, (data) => {
            if (data && data.length > 0) {
                setCOAs(data);
            } else {
                CatalogItemApi.getBatchesByProductId(variant.productId, (data2) => {
                    setCOAs(data2);
                })
            }
        })
    }

    const getCategory = (product) => {
        CatalogItemApi.getCategory(product.categoryHandle, (category) => {
            setCategory(category);
        })
    }

    const processProduct = (product, variant) => {
        const flavorName = findOptionWithLowestSortOrder(product)?.displayName;

        setVariant(variant);
        setProduct(product);

        const selectedButtons = deriveConstraintsFromVariant(variant);
        const flavorValue = selectedButtons[flavorName];

        const fixedOption = product.variantOptions.find((option) => option.isFixed === true);

        if (fixedOption) {
            setKeyOptionName(fixedOption.displayName);
            setKeyOptionValue(selectedButtons[fixedOption.displayName]);
            setFixedOption(true);
        }

        CatalogItemApi.getButtonPermutations(
            product.productId, selectedButtons, flavorName, flavorValue,
            fixedOption ? fixedOption.displayName : null,
            fixedOption ? selectedButtons[fixedOption.displayName] : null, (permutations) => {
                setSelectedOptions(selectedButtons);
                setButtonPermutations(permutations);
            })

        setLoading(false);

        getCOAs(variant);
        getCategory(product);
    }

    const onAddToCart = (callback) => {
        addToCart(getForm(), callback);
    }

    const onBuyItNow = (callback) => {
        buyItNow(getForm(), callback);
    }
    return (
        <CatalogItemContext.Provider
            value={{
                variant,
                setVariant,
                flavor,
                setFlavor,
                product,
                setProduct,
                loading,
                setLoading,
                coas,
                category,
                setCOAs,
                buttonPermutations,
                mode,
                onSelectOption,
                onDownloadCOA,
                onAddToCart,
                onBuyItNow
            }}
        >
            <>{children}</>
        </CatalogItemContext.Provider>
    );
}