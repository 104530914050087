import React, {forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import {Close, Search} from "@mui/icons-material";
import {
    Chip,
    ClickAwayListener,
    List,
    ListItemButton,
    ListSubheader,
    Paper,
    Popper,
    TextField,
    Typography
} from "@mui/material";
import PlatformSpace from "./platformSpace";
import {styled} from "@mui/styles";
import {debounce} from "lodash";
import PublicContext from "../layout/publicContext";
import {isMobile} from "react-device-detect";
import getImageUrl from "../util/getImageUrl";
import PriceTag from "./platformPriceTag";
import {calculatePrices} from "../util/moneyUtil";

const PlatformSearchField2 = forwardRef(({clickHandler, changeHandler, placeholder, height, ...allProps}, ref) => {
    const [hover, setHover] = useState(false);
    const [focused, setFocused] = useState(false)
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const textFieldRef = useRef(null);
    const [textFieldWidth, setTextFieldWidth] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [maxHeight, setMaxHeight] = useState(null);
    const {setBlurBackground} = useContext(PublicContext);

    useImperativeHandle(ref, () => ({
        clearTextField: () => {
            setInputValue('');
            setOpen(false);
        }
    }));

    useLayoutEffect(() => {
        if (textFieldRef.current) {
            setTextFieldWidth(textFieldRef.current.offsetWidth);
        }
    }, []);

    useLayoutEffect(() => {
        if (anchorEl) {
            const spaceToBottom = window.innerHeight - anchorEl.getBoundingClientRect().bottom;
            setMaxHeight(`${spaceToBottom / 2}px`);  // subtracting 20 or any value you prefer for some bottom padding
        }
    }, [anchorEl]);

    useEffect(() => {
        setBlurBackground(open);
    }, [open]);

    const onMouseOver = () => setHover(true);

    const onMouseOut = () => {
        if (!focused) {
            setHover(false);
        }
    }

    const onBlur = () => {
        setHover(false);
        setFocused(false);
    }

    const onFocus = () => setFocused(true);

    const debouncedChangeHandler = useRef(debounce((value, callback) => {
        if (typeof changeHandler === "function") {
            changeHandler(value, callback);
        }
    }, 300)).current;

    const onChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (!value.trim()) {
            setOpen(false);
            return;
        }

        debouncedChangeHandler(value, (filteredSuggestions) => {
            setSuggestions(filteredSuggestions);
            setOpen(!!filteredSuggestions.length);
        });

        setAnchorEl(textFieldRef.current);
    };

    const onClick = (product, variant) => {
        if (typeof clickHandler === "function") {
            clickHandler(product, variant);
        }

        onReset();
    }

    const onReset = () => {
        setOpen(false);
        setInputValue("");

        if (typeof changeHandler === "function") {
            changeHandler("");
        }
    }

    return (
        <React.Fragment>
            <TextField
                {...allProps}
                ref={textFieldRef}
                placeholder={placeholder}
                onFocus={onFocus}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onBlur={onBlur}
                onChange={onChange}
                variant="outlined"
                value={inputValue}
                inputProps={{autoComplete: 'off'}}
                sx={{
                    '& .MuiSvgIcon-root': {
                        color: hover ? "black !important" : "rgba(9,45,33,0.9) !important"
                    },
                    '& .MuiInputAdornment-root': {
                        transition: "background .3s ease-out",
                        backgroundColor: hover ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.4)",
                        marginRight: 0
                    },
                    '& .MuiInputAdornment-positionStart': {
                        padding: "21.5px 12px",
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 12,
                    },
                    '& .MuiInputAdornment-positionEnd': {
                        padding: "21.5px 12px",
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        marginLeft: 0
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0 !important",
                    },
                    "& .MuiOutlinedInput-root": {
                        padding: 0,
                        background: "rgba(255, 255, 255, 0.2)",
                        borderRadius: "12px !important",
                    },
                    "& .MuiOutlinedInput-input": {
                        transition: "background .3s ease-out",
                        backgroundColor: hover ? "rgba(255, 255, 255, 1)" : "rgba(253,253,253,0.7)",
                        borderRadius: 0,
                        color: hover ? "black !important" : "rgba(9,45,33,0.9) !important",
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start"
                                        onFocus={onFocus}
                                        onMouseOver={onMouseOver}
                                        onMouseOut={onMouseOut}
                                        onBlur={onBlur}>
                            <Search/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end"
                                        onFocus={onFocus}
                                        onMouseOver={onMouseOver}
                                        onMouseOut={onMouseOut}
                                        onBlur={onBlur}>
                            {inputValue?.length > 0 && <Close onClick={onReset}/>}
                        </InputAdornment>
                    ),
                }}
            />
            <ClickAwayListener onClickAway={() => onReset()}>
                <Popper open={open} anchorEl={anchorEl} placement="bottom-start"
                        sx={{zIndex: 9999, overflowY: 'auto', height: height || maxHeight || "inherit"}}>
                    <Paper style={{
                        width: textFieldWidth,
                        borderRadius: isMobile ? 0 : 12,
                        marginTop: isMobile ? 30 : 15,
                        padding: 5
                    }}>
                        <PlatformSpace height={10}/>
                        <List>
                            {suggestions.map((product, productIndex) => {
                                if (product.variants.length !== 0) {
                                    return (
                                        <React.Fragment key={productIndex}>
                                            <ListSubheader component="div">
                                                <Typography variant="body1" sx={{
                                                    opacity: 0.7,
                                                    textTransform: "uppercase",
                                                    fontSize: "0.8rem"
                                                }}>
                                                    {product.name}
                                                </Typography>
                                            </ListSubheader>
                                            <PlatformSpace height={5}/>
                                            {product.variants.length === 0 ? (
                                                <ListItemButton>No variants available</ListItemButton>
                                            ) : (
                                                product.variants.map((variant, variantIndex) => {
                                                    const imageUrl = getImageUrl(variant, 0);
                                                    const {percentOff} = calculatePrices(variant, 1);

                                                    return (
                                                        <ListItemButton onClick={() => onClick(product, variant)}
                                                                        key={variantIndex}
                                                                        sx={{
                                                                            padding: "4px 16px",
                                                                            borderRadius: 2,
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}>
                                                            <Image src={imageUrl}/>
                                                            <div style={{marginLeft: 7}}>
                                                                <div
                                                                    style={{marginBottom: (variant.variantOptionValues && variant.variantOptionValues[0]?.value) ? "-10px" : "0"}}>
                                                                    <Typography variant="body4" sx={{fontWeight: 600}}>
                                                                        {variant.name}{percentOff && <SquareChip label={`${percentOff}% OFF`} />}
                                                                    </Typography>
                                                                </div>
                                                                {variant.variantOptionValues && variant.variantOptionValues[0]?.value && (
                                                                    <div>
                                                                        <Typography variant="caption"
                                                                                    sx={{color: "text.secondary"}}>
                                                                            {variant.variantOptionValues[0].value}
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </ListItemButton>
                                                    );
                                                })
                                            )}
                                            {productIndex !== suggestions.length - 1 && <PlatformSpace height={20}/>}
                                        </React.Fragment>
                                    )
                                }
                            })}
                            {isMobile && <PlatformSpace height={150}/>}
                        </List>
                    </Paper>
                </Popper>
            </ClickAwayListener>
        </React.Fragment>
    );
});

export default PlatformSearchField2;

const SquareChip = styled(Chip)({
    backgroundColor: "rgba(142,243,142,0.4) !important",
    borderRadius: 3, // Less rounded corners to make it appear more square
    padding: 0, // Reduced padding for a tighter look
    marginLeft: 5,
    height: '20px !important', // Optional: adjust height to make it look more square
    '& .MuiChip-label': {
        padding: '0px 8px', // Adjust padding inside the label
    },
});

const Image = styled('img')(({theme}) => ({
    opacity: 0.99,
    objectFit: "cover",
    borderRadius: 4,
    pointerEvents: "none",
    width: 30,
    height: 30,
    marginRight: 7
}));