import React, {useCallback, useContext, useEffect, useState, useRef} from "react";
import CheckoutAPI from "./checkoutAPI";
import {splitCreditCardDate} from "../../components/util/stringUtil";
import {useHistory} from "react-router-dom";
import CartContext from "../cart/cartContext";
import PlatformFormContext from "../../components/platform/platformFormContext";
import CartApi from "../cart/cartApi";
import {debounce} from "lodash";
import {isMobile} from "react-device-detect";

export const CheckoutContext = React.createContext({});
export default CheckoutContext;

const SKIP_VALIDATION = false;

export const CheckoutContextProvider = ({children}) => {
    const {getForm, getFormValue, setFormValue, addChangeHandler, getFormValueDTO} = useContext(PlatformFormContext);
    const {emptyCart, updateCart} = useContext(CartContext);
    const history = useHistory();
    const [wizardStep, setWizardStep] = useState(1);
    const [processing, setProcessing] = useState(false);
    const [cartChecksum, setCartChecksum] = useState(null);
    const [couponError, setCouponError] = useState(null);
    const [shippingQuote, setShippingQuote] = useState([]);
    const [discount, setDiscount] = useState(null);
    const [loadingShipping, setLoadingShipping] = useState(false);
    const [addressValidated, setAddressValidated] = useState(null);
    const [recommendedAddress, setRecommendedAddress] = useState(null);
    const [recommendedAddressOpen, setRecommendedAddressOpen] = useState(false);
    const [defaultServiceToken, setDefaultServiceToken] = useState(null);
    const [orderErrorMessage, setOrderErrorMessage] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [showCaptcha, setShowCaptcha] = useState(false); // New state for showing captcha

    useEffect(() => {
        if (wizardStep > 1) {
            if (!isMobile) {
                refreshCart();
            }
        }
    }, [wizardStep]);

    useEffect(() => {
        if (isMobile) {
            const addressFields = ["country", "state", "street1", "street2", "city", "zip"];
            addChangeHandler((changedField, groupBy) => {
                if (changedField && addressFields.indexOf(changedField.name) > -1 && groupBy === "shippingAddress") {
                    onValidateAddressAndCheckQuote((code) => {
                        if (code === "RECOMMENDATION") {
                            setRecommendedAddressOpen(true);
                        } else if (code === "VALID") {
                            setShippingQuote([]);
                            onShippingQuote();
                        }
                    });
                }
            });
        }
        onReloadForm();
    }, []);

    const onWizardStep = (step) => {
        if (wizardStep === 1) {
            onValidateAddressAndCheckQuote((code) => {
                if (code === "RECOMMENDATION") {
                    setRecommendedAddressOpen(true);
                } else if (code === "VALID") {
                    setShippingQuote([]);
                    onShippingQuote();
                    setWizardStep(step);
                }
            });
        } else {
            if (step === 2 && addressValidated === "VALID") {
                onShippingQuote();
            }
            setWizardStep(step);
        }
    };

    const onValidateAddressAndCheckQuote = (callback) => {
        const shippingAddress = getFormValueDTO("shippingAddress");

        if (!validateAddressFields(shippingAddress)) {
            if (SKIP_VALIDATION === true) {
                callback("INVALID");
            }
            CheckoutAPI.validateAddress(shippingAddress, (result) => {
                if (result.code === "VALID") {
                    setAddressValidated("VALID");
                    setRecommendedAddress(result.recommendedAddress);
                } else if (result.code === "RECOMMENDATION") {
                    setShippingQuote([]);
                    setRecommendedAddress(result.recommendedAddress);
                    setAddressValidated("RECOMMENDATION");
                } else if (result.code === "INVALID") {
                    setShippingQuote([]);
                    setRecommendedAddress(null);
                    setAddressValidated("INVALID");
                }
                if (typeof callback === "function") {
                    callback(result.code, result.recommendedAddress);
                }
            });
        } else {
            if (SKIP_VALIDATION === true) {
                callback("VALID");
            }
            // Not sure what this is for, but causes error to show up by default
            setAddressValidated("INVALID");

            if (typeof callback === "function") {
                callback("INVALID");
            }
        }
    };

    const onAcceptRecommendation = () => {
        let shippingAddress = getFormValueDTO("shippingAddress");
        shippingAddress.street1 = recommendedAddress.street1;
        shippingAddress.city = recommendedAddress.city;
        shippingAddress.state = recommendedAddress.state;
        shippingAddress.zip = recommendedAddress["zip"];

        setFormValue("shippingAddress", mapDTOToAddress(shippingAddress), "shippingAddress");

        setRecommendedAddress(null);
        setRecommendedAddressOpen(false);
        setAddressValidated("VALID");
        setShippingQuote([]);
        onShippingQuote();
        setWizardStep(wizardStep + 1);

        refreshCart();
    };

    const onRejectRecommendation = () => {
        setRecommendedAddressOpen(false);
    };

    const onReloadForm = () => {
        CartApi.getCart((fetchedCart) => {
            const cart = fetchedCart?.cart;
            const billingAddressType = cart.billingAddressType || "SHIPPING_ADDRESS";
            setFormValue("couponCode", cart.couponCode);
            setFormValue("serviceToken", getFormValue("serviceToken"));
            setFormValue("email", cart.email);
            setFormValue("paymentType", cart.paymentType || "CREDIT_CARD");
            setFormValue("billingAddressType", billingAddressType);
            setFormValue("shippingAddress", mapDTOToAddress(cart.shippingAddress));
            if (billingAddressType === "SHIPPING_ADDRESS") {
                setFormValue("billingAddress", mapDTOToAddress(cart.shippingAddress));
            } else {
                setFormValue("billingAddress", mapDTOToAddress(cart.billingAddress));
            }
            onValidateAddressAndCheckQuote();
            // setFormValue("cardNumber", "1212 1212 1212 1212");
            // setFormValue("cardholder", "Paul Zain");
            // setFormValue("expirationDate", "12/24");
            // setFormValue("cvv", "123");
        });
    };

    const onUpdateDiscount = () => {
        refreshCart((cart) => {
            onShippingQuote();
        });
    };

    const onClearDiscount = () => {
        setFormValue("couponCode", "");
        setFormValue("serviceToken", "");
        setTimeout(() => {
            setDiscount(null);
            refreshCart((cart) => {
                onShippingQuote();
            });
        }, 350);
    };

    const refreshCart = (callback) => {
        let shippingAddress = getFormValueDTO("shippingAddress");
        let billingAddress = getFormValueDTO("billingAddress");

        updateCart({
            couponCode: getFormValue("couponCode"),
            serviceToken: getFormValue("serviceToken"),
            email: getFormValue("email"),
            billingAddressType: getFormValue("billingAddressType"),
            paymentType: getFormValue("paymentType"),
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
        }, (success, cart) => {
            setCartChecksum(cart.checksum);
            if (cart.errors && cart.errors.length > 0) {
                setCouponError(cart.errors[0]);
            } else {
                setCouponError(null);
            }
            if (cart?.discountAmount > 0 && cart?.couponCode) {
                setDiscount({
                    amount: cart.discountAmount,
                    name: cart.couponName,
                });
            }
            if (typeof callback === "function") {
                callback(cart.cart);
            }
        });
    };

    const mapDTOToAddress = (data) => {
        if (!data) {
            return [];
        }

        const keysToInclude = ["country", "state", "firstName", "lastName", "street1", "city", "zip", "phone"];

        return keysToInclude.map(key => {
            return {
                name: key, value: data[key] || ''
            };
        });
    };

    const onReCAPTCHAChange = (token) => {
        setRecaptchaToken(token);
    };

    const onCheckout = () => {
        const payload = getForm();
        const rate = shippingQuote.rates.find((rate) => rate.serviceToken === payload?.serviceToken);

        if (payload.serviceToken === null || !rate) {
            setWizardStep(2);
            return;
        }

        if (showCaptcha && !recaptchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }

        setProcessing(true);

        refreshCart((cart) => {
            const checkoutPayload = {
                ...convertPayload(payload),
                recaptchaToken,
            };

            CheckoutAPI.checkout(checkoutPayload, (data) => {
                const {order} = data;

                history.push({
                    pathname: `/order/receipt`, state: {
                        order: order,
                    }
                });

                emptyCart();
            }, (err) => {
                setProcessing(false);
                const {type, message} = err.data;
                if (type === "CaptchaRequiredException") {
                    setOrderErrorMessage(message);
                    setRecaptchaToken(null);
                    setShowCaptcha(true);
                } else {
                    setOrderErrorMessage(message);
                }
            });
        });
    };

    const onShippingQuote = useCallback(debounce((callback) => {
        setLoadingShipping(true);

        const formResult = getForm();
        if (formResult) {
            const {shippingAddress} = formResult;

            if (Array.isArray(shippingAddress)) {
                const result = {};
                for (let item of shippingAddress) {
                    result[item.name] = item.value;
                }
                result["country"] = "US";

                CheckoutAPI.getShippingRates(result, (shippingQuoteNew) => {
                    setShippingQuote(shippingQuoteNew);
                    const serviceToken = getFormValue("serviceToken");

                    if (!serviceToken || serviceToken.length === 0) {
                        const defaultServiceToken = shippingQuoteNew && shippingQuoteNew?.rates?.length > 0 ?
                            shippingQuoteNew.rates[0].serviceToken : null;
                        setFormValue("serviceToken", defaultServiceToken);
                        setDefaultServiceToken(defaultServiceToken);
                    } else {
                        setDefaultServiceToken(serviceToken);
                    }

                    if (typeof callback === "function") {
                        callback(shippingQuoteNew);
                    }

                    setLoadingShipping(false);
                });
            } else if (typeof callback === "function") {
                callback([]);
                setLoadingShipping(false);
            } else {
                console.warn("No callback specified");
            }
        }
    }, 1200), [getForm, setShippingQuote]);

    function convertPayload(payload) {
        const creditCardExpiration = splitCreditCardDate(payload.expirationDate);

        return {
            creditCard: {
                cardNumber: payload.cardNumber,
                cardholder: payload.cardholder,
                cvv: payload.cvv,
                expirationMonth: creditCardExpiration.month,
                expirationYear: creditCardExpiration.year,
            },
            optIn: payload.optIn,
            shipping: {
                serviceToken: getFormValue("serviceToken"),
                shipmentId: shippingQuote.shipmentId,
            },
        };
    }

    function validateAddressFields(data) {
        if (typeof data !== 'object' || data === null) {
            return {_error: 'Input data must be an object and cannot be null'};
        }

        const fields = ['firstName', 'lastName', 'street1', 'city', 'state', 'zip'];
        const errors = {};

        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        const stateRegex = /^[A-Z]{2}$/;

        fields.forEach(field => {
            if (!(field in data)) {
                errors[field] = 'Field is required';
            } else if (typeof data[field] !== 'string') {
                errors[field] = 'Field must be a string';
            } else if (data[field].trim() === '') {
                errors[field] = 'Field cannot be empty';
            } else {
                switch (field) {
                    case 'zip':
                        if (!zipCodeRegex.test(data[field])) {
                            errors[field] = 'Zip code must be 5 digits or 9 digits in the format XXXXX-XXXX';
                        }
                        break;
                    case 'state':
                        if (!stateRegex.test(data[field])) {
                            errors[field] = 'State must be a valid 2-letter US state abbreviation';
                        }
                        break;
                    case 'street1':
                        if (data[field].trim().length < 5) {
                            errors[field] = 'Street address is too short';
                        }
                        break;
                }
            }
        });

        return Object.keys(errors).length > 0 ? errors : null;
    }

    return (<CheckoutContext.Provider
        value={{
            wizardStep,
            setWizardStep,
            processing,
            setProcessing,
            shippingQuote,
            couponError,
            cartChecksum,
            discount,
            loadingShipping,
            addressValidated,
            onShippingQuote,
            recommendedAddress,
            setRecommendedAddress,
            recommendedAddressOpen,
            setRecommendedAddressOpen,
            defaultServiceToken,
            orderErrorMessage,
            showCaptcha,
            onWizardStep,
            onAcceptRecommendation,
            onRejectRecommendation,
            onUpdateDiscount,
            onReloadForm,
            onClearDiscount,
            onCheckout,
            refreshCart,
            onReCAPTCHAChange,
        }}
      >
          <>{children}</>
      </CheckoutContext.Provider>);
}
