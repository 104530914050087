import axios from "axios";
import qs from "qs";
import {deleteObject} from "./components/shared/util/storageUtil";
import {manageCartIDCookie} from "./components/util/cart";
import * as Sentry from "@sentry/react";

const init = () => {
    return axios.create({
        baseURL: `//${process.env.REACT_APP_HOST || "localhost:5001"}`,
        timeout: 31000,
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Cart-ID': manageCartIDCookie() // Add your Cart-ID here
        }
    });
};

//https://www.smashingmagazine.com/2020/06/rest-api-react-fetch-axios/
//https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
//https://axios-http.com/docs/handling_errors
//https://github.com/axios/axios

export default {

    POST: function (config, successCallback, errorCallback) {
        init().post(config.url, config.params)
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                this.LOGOUT();
            }
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                    Sentry.captureException(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    PUT: function (config, successCallback, errorCallback) {
        init().put(config.url, config.params)
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                    Sentry.captureException(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    GET: function (config, successCallback, errorCallback) {
        init().get(config.url, {params: config.params, paramsSerializer: params => {
                return qs.stringify(params)
            }})
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                this.LOGOUT();
            }
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                    Sentry.captureException(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    DELETE: function (config, successCallback, errorCallback) {
        init().delete(config.url, {data: config.params})
            .then(response => {
                if (typeof successCallback === 'function') {
                    successCallback(response.data);
                } else {
                    console.log('callback: ', response)
                }
            }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                this.LOGOUT();
            }
            if (typeof errorCallback === 'function') {
                if (error.response) {
                    errorCallback(error.response);
                    Sentry.captureException(error.response);
                }
            } else {
                console.log('error: ', error)
            }
        })
    },

    LOGOUT: function() {
        console.log('logout')
        deleteObject("user");
        deleteObject("token");
        deleteObject("cart");
    }
}