import {useContext, useEffect} from 'react';
import AppContext from "../../appContext";
import PublicPromoBanner from "../layout/publicPromoBanner";

export default function useLayoutOptions({
                                             backgroundComponent = null,
                                             headerComponent = null,
                                             footerComponent = null,
                                             saleComponent = PublicPromoBanner,
                                             blur = "0px",
                                             topMargin = 0
                                         } = {}) {

    const {onLayoutOptions, onLayoutReset} = useContext(AppContext);

    useEffect(() => {
        onLayoutOptions({
            backgroundComponent,
            headerComponent,
            footerComponent,
            saleComponent,
            blur,
            topMargin
        });
        return () => onLayoutReset();
    }, [backgroundComponent, footerComponent, blur, topMargin]);
}
