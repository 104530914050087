import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {Chip, Fade, Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import {isMobile} from "react-device-detect";
import PlatformSpace from "../../../components/platform/platformSpace";
import formatToUSD from "../../../components/util/moneyUtil";
import Grid from "@mui/material/Grid";
import PlatformCartSplitButton from "../../../components/platform/platformCartSplitButton";
import PlatformTextField from "../../../components/platform/platformTextField";
import Button from "@mui/material/Button";
import CatalogItemContext from "./catalogItemContext";
import AppContext from "../../../appContext";
import useImageUrl from "../../../components/hook/useImageUrl";
import useThumbnailUrls from "../../../components/hook/useThumbnailUrls";
import PlatformMarginBox from "../../../components/platform/platformMarginBox";
import usePriceCalculation from "../../../components/hook/usePriceCalculation";

export default function CatalogItemDetail(props) {
    const {
        onAddToCart,
        onBuyItNow,
        variant,
        flavor,
        product,
        onSelectOption,
        buttonPermutations,
        loading,
        mode,
    } = useContext(CatalogItemContext);
    const [selectedImage, setSelectedImage] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const {store} = useContext(AppContext);
    const { formattedPrice, formattedSalePrice, percentOff } = usePriceCalculation(variant, quantity);

    const isSoldOut = useMemo(() => {
        return false//variant?.inventories?.find(s => s.storeId === store?.id)?.inventoryLevel <= 0;
    }, [variant, store]);

    const onAddItemToCart = (callback) => {
        onAddToCart(callback);
    }

    const onBuyItemNow = (callback) => {
        onBuyItNow(callback);
    }

    const onSelectImage = (idx) => {
        setSelectedImage(idx);
    }

    const onQuantityChange = (value) => {
        setQuantity(value);
    }

    const imageUrl = useImageUrl(variant, selectedImage);
    const thumbnailUrls = useThumbnailUrls(variant);

    const addToCartText = formattedSalePrice ? (
        <React.Fragment>
            Add to cart {formattedSalePrice}&nbsp;
            <SalePriceButtonText>{formattedPrice}</SalePriceButtonText>
        </React.Fragment>
    ) : (
        `Add to cart ${formattedPrice}`
    );

    return (
        <PlatformMarginBox>
            <Fade in={!loading && product.name && flavor} timeout={500}>
                <MainContent loading={loading || !product.name || !flavor}>
                    <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 5}>
                        <div style={{width: isMobile ? (mode === "modal" ? "80vw" : "92vw") : "65vw"}}>
                            <ImageOutline>
                                {!loading &&
                                    <ImageContainer>
                                        <Image src={imageUrl} width="100%" />
                                        {isSoldOut && <SoldOutBanner>Sold Out</SoldOutBanner>}
                                        {variant.salePrice !== null && <OnSaleBanner>On Sale!</OnSaleBanner>}
                                    </ImageContainer>
                                }
                            </ImageOutline>
                            <PlatformSpace height={10}/>
                            {!loading &&
                                <Stack justifyContent="flex-start" direction="row" spacing={1}>
                                    {thumbnailUrls.map((thumbnailUrl, idx) => {
                                        return (
                                            <ThumbnailOutline>
                                                <ThumbnailContainer onClick={() => onSelectImage(idx)}>
                                                    <Thumbnail src={thumbnailUrl} width="100%" />
                                                </ThumbnailContainer>
                                            </ThumbnailOutline>
                                        )
                                    })}
                                </Stack>
                            }
                        </div>
                        <ProductInfo>
                            <PlatformSpace height={15}/>
                            <Stack spacing={1} alignItems="flex-start" justifyContent="flex-start" direction="row">
                                <ProductName>{flavor}</ProductName>
                                {isSoldOut && <Chip size="small" label="SOLD OUT" sx={{backgroundColor: "rgba(255, 215, 157, 1)"}}/>}
                            </Stack>
                            <PlatformSpace height={10}/>
                            <Aka>{product.name}</Aka>
                            <PlatformSpace height={10}/>
                            <Typography variant="h6">
                                {formattedSalePrice ? (
                                    <>
                                        {formattedSalePrice}&nbsp;
                                        <SalePrice>{formattedPrice}</SalePrice>&nbsp;
                                        {percentOff && <SaleText>{percentOff}% OFF</SaleText>}
                                    </>
                                ) : (
                                    formattedPrice
                                )}
                            </Typography>
                            {!loading && <PlatformSpace height={30}/>}
                            <ItemOutline>
                                <div style={{height: "100%"}}>
                                    <Typography variant="h6">
                                        {variant?.descriptionByAI && variant?.descriptionByAI}
                                        {!variant?.descriptionByAI &&
                                            "An express ticket to outer space, for experienced users only. Blast off."
                                        }
                                    </Typography>
                                    {!loading && <PlatformSpace height={30}/>}
                                    <Stack spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                        {/*{!loading &&*/}
                                        {/*    <React.Fragment>*/}
                                        {/*        <FormLabel>Current price</FormLabel>*/}
                                        {/*        <PriceLabel>{formatToUSD(variant?.price)}</PriceLabel>*/}
                                        {/*        <PlatformSpace height={10}/>*/}
                                        {/*    </React.Fragment>*/}
                                        {/*}*/}
                                        {Object.keys(buttonPermutations).map((key, idx) => (
                                            <React.Fragment key={idx}>
                                                <FormLabel>{key}</FormLabel>
                                                <Grid container spacing={0} alignItems="flex-start" justifyContent="flex-start">
                                                    {buttonPermutations[key].map((item, idx2) => (
                                                        <React.Fragment>
                                                            {(item.soldOut !== true && item.status !== "DISABLED") &&
                                                                <Grid item sx={{padding: "0 8px 10px 0"}} key={idx2}>
                                                                    <OptionButton
                                                                        variant="outlined"
                                                                        soldOut={item.soldOut === true}
                                                                        selected={item.status === "SELECTED"}
                                                                        disabled={item.status === "DISABLED" || item.soldOut === true}
                                                                        onClick={() => onSelectOption(key, item.value)}
                                                                    >
                                                                        {item.value}
                                                                    </OptionButton>
                                                                </Grid>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                            </React.Fragment>
                                        ))
                                        }
                                        <Stack spacing={2} alignItems="center" justifyContent="center" direction="row">
                                            <Typography variant="body4" gutterBottom>
                                                Ships within 24 hours
                                            </Typography>
                                        </Stack>
                                        {(!isSoldOut || loading) &&
                                            <React.Fragment>
                                                <Stack spacing={2} alignItems={isMobile ? "flex-start" : "flex-end"}
                                                       justifyContent="flex-start" direction={isMobile ? "column" : "row"}>
                                                    <PlatformTextField
                                                        centered
                                                        onIncrementDecrementChange={onQuantityChange}
                                                        name="quantity"
                                                        label="Quantity"
                                                        sx={{width: 125}}
                                                        defaultValue={1}
                                                        type="number"
                                                    />
                                                    <div style={{position: "relative", width: "100%", height: 45}}>
                                                        <PlatformCartSplitButton
                                                            addToCartText={addToCartText}
                                                            height={45}
                                                            disabled={isSoldOut}
                                                            width={isMobile ? "80vw" : "350px"}
                                                            cartHandler={onAddItemToCart}
                                                            buyNowHandler={onBuyItemNow}
                                                        />
                                                    </div>
                                                </Stack>
                                            </React.Fragment>
                                        }
                                        <PlatformSpace height={5}/>
                                        <Stack spacing={2} alignItems={isMobile ? "flex-start" : "center"}
                                               justifyContent="center" direction={isMobile ? "column" : "row"}>
                                            <Typography variant="body4">
                                                ✦ Free shipping on orders $99+
                                            </Typography>
                                            <Typography variant="body4">
                                                ✦ 100-day free returns
                                            </Typography>
                                        </Stack>
                                        {isSoldOut &&
                                            <React.Fragment>
                                                <PlatformSpace height={55}/>
                                                <Typography variant="body3">
                                                    This product is currently out of stock
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    </Stack>
                                </div>
                            </ItemOutline>
                        </ProductInfo>
                    </Stack>
                    <PlatformSpace height={20}/>
                </MainContent>
            </Fade>
        </PlatformMarginBox>
    )
}

const SalePriceButtonText = styled('span')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1rem",
    color: "lightgreen",
    textDecoration: "line-through"
}));

const SalePrice = styled('span')(({ theme }) => ({
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "green",
    textDecoration: "line-through"
}));

const SaleText = styled('span')(() => ({
    color: "green",
}));

const OnSaleBanner = styled('div')(() => ({
    position: 'absolute',
    top: 100, // Adjusted to move the banner to the top
    left: -40, // Adjusted to move the banner to the left
    textTransform: "uppercase",
    backgroundColor: "rgba(170,224,170,0.3) !important",
    color: "rgb(22,108,75)",
    padding: '10px 20px', // Increased padding
    width: 200, // You can set a specific width if you want
    fontSize: '1em', // Larger font size
    fontWeight: 600,
    transform: 'rotate(-45deg)',
    transformOrigin: 'top left', // Adjusted to keep the rotation centered on the top left
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
}));

const SoldOutBanner = styled('div')(() => ({
    position: 'absolute',
    bottom: 100,
    right: -40,
    //backgroundColor: 'red',
    textTransform: "uppercase",
    backgroundImage: "linear-gradient(to top, rgb(41, 31, 55), rgb(41, 31, 55), rgb(2, 2, 3))",
    color: 'white',
    padding: '10px 20px', // Increased padding
    width: 200, // You can set a specific width if you want
    fontSize: '1em', // Larger font size
    fontWeight: 600,
    transform: 'rotate(-45deg)',
    transformOrigin: 'bottom right', // Adjust this if needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
}));

const ProductInfo = styled("div")(({ theme }) => ({
    width: "100%",
    //textAlign: isMobile ? "center" : "inherit"
}));

const MainContent = styled("div")(({ theme, loading }) => ({
    width: "100%",
    ...(loading === true && {
        minHeight: "90vh",
    }),
}));

const ItemOutline = styled("div")(({ theme }) => ({
    borderRadius: 12,
    overflow: "hidden",
    //border: "1px solid rgba(59,42,84, 0.3)"
}));

const ImageOutline = styled("div")(({ theme }) => ({
    //border: "1px solid rgba(59,42,84, 0.3)",
    borderRadius: 12,
    width: "100%",
    overflow: "hidden",
    padding: 5,
}));

const ThumbnailOutline = styled("div")(({ theme }) => ({
    //border: "1px solid rgba(59,42,84, 0.3)",
    borderRadius: 12,
    overflow: "hidden",
}));

const ImageContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    //backgroundColor: "black",
    borderRadius: "12px 12px 0 0",
    transition: ".3s ease-in-out",
    transform: !hover ? "scale(1)" : "scale(1.1)"
}));

const Image = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: isMobile ? "85vw" : "35vw",
    width: "100%",
    borderRadius: 12,
    pointerEvents: "none"
}));

const ThumbnailContainer = styled('div')(({ hover }) => ({
    zIndex: 1,
    backgroundColor: "black",
    borderRadius: 12,
    transition: ".3s ease-in-out",
    transform: !hover ? "scale(1)" : "scale(1.1)",
    cursor: "pointer"
}));

const Thumbnail = styled('img')(({ size }) => ({
    opacity: 0.99,
    objectFit: "cover",
    height: "12vh",
    maxHeight: 125,
    width: isMobile ? "20vw" : "8vw",
    borderRadius: 12,
    pointerEvents: "none"
}));

const FormLabel = styled('div')(({ theme }) => ({
    fontSize: "1.1rem",
    fontFamily: "Poppins !important",
    color: "rgb(112, 122, 131)",
    fontWeight: 600
}));

const OptionButton = styled(Button)(({ theme, selected, disabled, soldOut }) => ({
    minWidth: '130px !important',
    backgroundImage: 'none !important',
    backgroundColor: 'rgba(0, 255, 0, 0.05) !important',
    color: 'black !important',
    lineHeight: '1.3rem !important',
    minHeight: 50,
    //border: '3px solid rgba(0, 0, 0, 0.1) !important', // Increased border thickness
    ...(selected === true && {
        color: '#f7f6f2 !important',
        //backgroundColor: 'rgba(0, 255, 0, 0.225) !important',
        backgroundColor: 'rgba(9,45,33,0.9) !important'
        //border: '3px solid black !important', // Increased border thickness
    }),
    ...(disabled === true && {
        border: '3px dashed rgba(0, 0, 0, 0.225) !important', // Increased border thickness
        opacity: '0.8 !important',
        color: 'grey !important', // Text appears greyed out
    }),
    ...(soldOut === true && {
        textDecoration: "line-through !important",
        border: '3px dashed rgba(0, 0, 0, 0.225) !important',
    }),
}));

const ProductName = styled('div')(({ theme }) => ({
    fontWeight: 700,
    fontSize: "3rem",
    lineHeight: "3rem",
    color: theme.custom.primaryTextColor,
}));

const Aka = styled('div')(({ theme }) => ({
    fontWeight: 400,
    fontSize: "1.2rem",
    color: theme.custom.primaryTextColor,
}));