import React, {useContext, useRef} from "react";
import {FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import usePlatformSelect from "../hook/usePlatformSelect";
import {styled} from "@mui/styles";
import PlatformFormContext from "./platformFormContext";

export default function PlatformSelect(props) {
    const {
        onChange,
        width,
        fullWidth,
        name,
        label,
        required,
        options,
        defaultValue,
        errorText,
        groupBy,
        ...allProps
    } = props;
    const fieldRef = useRef(null); // Add a ref to the field
    const context = useContext(PlatformFormContext);
    const {value, valid, blurHandler, changeHandler} = usePlatformSelect({
        ...props,
        context,
        fieldRef
    });

    return (
        <PlatformFormContainer>
            {label && <PlatformFormLabel htmlFor={name}>{label}</PlatformFormLabel>}
            <PlatformFormControl variant="outlined" fullWidth={fullWidth}>
                <PlatformSelectStyled
                    ref={fieldRef}
                    valid={valid}
                    fullWidth={fullWidth}
                    sx={{width: width || "inherit"}}
                    input={
                        <OutlinedInput
                            id={name}
                            name={name}
                            autoComplete="address-level1" // Ensures this field is recognized as state/region
                            {...allProps}
                            inputProps={{
                                ...allProps.inputProps,
                                autoComplete: "address-level1", // Ensures this field is recognized as state/region
                            }}
                            error={!valid}
                            value={value}
                            onBlur={blurHandler}
                            onChange={changeHandler}
                            labelWidth={0}
                        />}
                >
                    {(options || []).map((option, idx) => (
                        <MenuItem key={idx} value={option.value}>
                            <PlatformListItemText>
                                {option.label}
                            </PlatformListItemText>
                        </MenuItem>
                    ))}
                </PlatformSelectStyled>
                {(!valid && errorText) && <FormHelperText style={{color: "red"}}>{errorText}</FormHelperText>}
            </PlatformFormControl>
        </PlatformFormContainer>
    );
};

const PlatformFormContainer = styled('div')({
    position: "relative"
});

const PlatformFormLabel = styled(InputLabel)({
    margin: "10px 0 10px 0",
    fontWeight: 400,
    color: "black",
    opacity: 1,
    flexGrow: 1
});

const PlatformFormControl = styled(FormControl)({
    width: "100%"
});

const PlatformSelectStyled = styled(Select)(({fullWidth, valid}) => ({
    flexGrow: 1,
    width: fullWidth ? "100%" : "500px",
    border: valid !== true ? "2px solid red" : "none"
}));

const PlatformListItemText = styled(ListItemText)({
    marginTop: 0,
    marginBottom: 0,
    '& .platformListItemPrimary': {
        fontSize: 14,
        color: "rgb(51, 51, 51)"
    }
});
